import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function AppHeader() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src="/logo192.png"
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="EAYP Logo"
          />
          <span className="d-none d-lg-inline"> East African Youth Panel</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#pricing">Events</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#founder">Founder</Nav.Link>
            
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#works">Gallery</Nav.Link>
            {/* <Nav.Link href="#teams">Teams</Nav.Link> */}
            <Nav.Link href="#testimonials">Testimonials</Nav.Link>
            <Nav.Link href="#sponsors">Sponsors</Nav.Link>
            {/* <Nav.Link href="#blog">Blog</Nav.Link> */}
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;
