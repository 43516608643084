import React from "react";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

const sponsorsData = [
  {
    id: 1,
    name: 'CRDB',
    logo: require("../assets/images/sponsors/img1.png"),
     link: 'https://www.crdbbank.co.tz'
  },
  {
    id: 2,
    name: 'UTT AMIS',
    logo: require("../assets/images/sponsors/img2.png"),
    link: 'https://www.uttamis.co.tz'
  },
  {
    id: 3,
    name: 'AirTanzania',
    logo: require("../assets/images/sponsors/img3.png"),
    link: 'https://www.airtanzania.co.tz'
  },
  {
    id: 4,
    name: 'Suti Fashion',
    logo: require("../assets/images/sponsors/img4.png"),
    link: 'https://www.sutifashion.co.tz'
  },
  {
    id: 5,
    name: 'Dorah Events',
    logo: require("../assets/images/sponsors/img5.png"),
    link: 'https://www.dorahevents.co.tz'
  },
  {
    id: 6,
    name: 'Aznas Arts Center',
    logo: require("../assets/images/sponsors/img6.png"),
    link: 'https://www.aznasartscenter.co.tz'
  },
  {
    id: 7,
    name: 'Inney Cosmetics',
    logo: require("../assets/images/sponsors/img7.png"),
    link: 'https://www.inneycosmetics.co.tz'
  },
  {
    id: 8,
    name: 'PhytoScience',
    logo: require("../assets/images/sponsors/img8.png"),
    link: 'https://www.phytoscience.co.tz'
  },
  {
    id: 9,
    name: 'Taste Me Dessert & Cafe',
    logo: require("../assets/images/sponsors/img9.png"),
    link: 'https://www.tasteme.co.tz'
  },
];

function SponsorsPage() {
    // Group sponsorsData into chunks of 3
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const groupedSponsors = chunkArray(sponsorsData, 3);

    return (
      <section id="sponsors" className="block sponsors-block">
        <Container fluid>
          <div className="title-holder">
            <h2>Our Sponsors</h2>
            <div className="subtitle">Supporters of East African Youth Panel</div>
          </div>
          <Carousel>
          {groupedSponsors.map((group, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-between">
                {group.map(sponsor => (
                  <a
                    href={sponsor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={sponsor.id}
                    className="d-block sponsor-image-wrapper"
                  >
                    <Image
                      src={sponsor.logo}
                      alt={sponsor.name}
                      className="sponsor-image"
                    />
                  </a>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        </Container>
      </section>
    );
  }
export default SponsorsPage;
