import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import img from "../assets/images/founder.jpeg";

function Founder() {
  return (
    <section id="founder" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Meet The Founder</h2>
          <div className="subtitle">Discover the Visionary Behind EAYP</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img} alt="Founder Image" style={{ width: "85%" }} />
          </Col>
          <Col sm={6}>
            <p>
              The East African Youth Panel (EAYP) is a dynamic platform
              dedicated to empowering the youth of East Africa. We believe in
              the power of community, innovation, and leadership, and our
              mission is to recognize and celebrate the outstanding achievements
              of young leaders, entrepreneurs, and change-makers across the
              region.
            </p>
            <p>
              Our founder, Joseph Kabam Thobias, is a visionary leader with a
              deep passion for youth empowerment. Growing up in East Africa,
              Joseph witnessed firsthand the challenges and potential of the
              region's youth. Determined to make a difference, he established
              EAYP to create a supportive and nurturing environment where young
              people can thrive.
            </p>
            <p>
              Under Joseph's leadership, EAYP has launched numerous initiatives
              focused on providing opportunities for growth, networking, and
              development. His commitment to ensuring that the voices of East
              African youth are heard and valued has driven EAYP to organize
              impactful programs and events.
            </p>
            <p>
              Through our highly anticipated awards program, we shine a
              spotlight on the exceptional contributions of young individuals in
              business, creativity, and social change. We invite you to join us
              in this journey of celebration and empowerment, as we work
              together to build a brighter future for the next generation of
              East African leaders.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Founder;
