import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { sendForm } from "@emailjs/browser";

function AppContact() {
  const handleSubmit = (e) => {
    e.preventDefault();

    sendForm(
      "service_y364ijm",
      "template_n1bzj45",
      e.target,
      "MeFTt48FqWITaXKVA"
    ).then(
      (result) => {
        alert("Message sent successfully!");
      },
      (error) => {
        alert("Error sending message. Please try again.");
      }
    );
  };
  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">get connected with us</div>
        </div>
        <Form className="contact-form" onSubmit={handleSubmit}>
          <Row>
            <Col sm={4}>
              <Form.Control
                type="text"
                name="name"
                autoComplete="name"
                placeholder="Enter your full name"
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="email"
                name="email"
                autoComplete="email"
                placeholder="Enter your email address"
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Control
                type="tel"
                name="phone"
                autoComplete="tel"
                placeholder="Enter your contact number"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Control
                as="textarea"
                name="message"
                placeholder="Enter your contact message"
                required
              />
            </Col>
          </Row>
          <div className="btn-holder">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </Container>
      <div className="google-map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3670.1522462523634!2d39.20822065127984!3d-6.792354622398229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2stz!4v1481805868782&markers=color:red%7Clabel:%7C-6.792354,39.208220"
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <Container fluid>
        <div className="contact-info">
          <ul>
            <li>
              <i className="fas fa-envelope"></i>
              info@youthpanel.co.tz
            </li>
            <li>
              <i className="fas fa-phone"></i>
              +255 653 077 768
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              Dar es Salaam, Tanzania
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
}

export default AppContact;
