import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const servicesData = [
  {
    id: 1,
    icon: "fas fa-users",
    title: "Youth Leadership Programs",
    description:
      "Empowering the next generation of East African leaders through mentorship, training, and leadership development programs.",
  },
  {
    id: 2,
    icon: "fas fa-lightbulb",
    title: "Innovation and Entrepreneurship",
    description:
      "Fostering innovation and entrepreneurship among East African youth by providing resources, training, and support to bring their ideas to life.",
  },
  {
    id: 3,
    icon: "fas fa-award",
    title: "Awards and Recognition",
    description:
      "Celebrating the achievements of young leaders, entrepreneurs, and change-makers through our prestigious awards program.",
  },
  {
    id: 4,
    icon: "fas fa-handshake",
    title: "Networking Opportunities",
    description:
      "Creating platforms for young people to connect, collaborate, and network with industry leaders and peers across East Africa.",
  },
  {
    id: 5,
    icon: "fas fa-book",
    title: "Educational Workshops",
    description:
      "Offering workshops and seminars on various topics including leadership, business skills, and personal development to equip youth with the necessary skills for success.",
  },
  {
    id: 6,
    icon: "fas fa-life-ring",
    title: "Support Services",
    description:
      "Providing ongoing support and guidance to help East African youth overcome challenges and achieve their goals.",
  },
];

function AppServices() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Our services</h2>
          <div className="subtitle">What we offer</div>
        </div>
        <Row>
          {servicesData.map((services) => {
            return (
              <Col sm={4} className="holder" key={services.id}>
                <div className="icon">
                  <i className={services.icon}></i>
                </div>
                <h3>{services.title}</h3>
                <p>{services.description}</p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;
