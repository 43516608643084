import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const pricingData = [
  {
    id: 1,
    plan: "Event Tickets",
    price: "NULL",
    features: [
      "Event Date:",
      "Close Date",
      "Refreshments",
      "Networking Opportunities",
      "Support & Assistance",
    ],
    link: "#",
  },
  {
    id: 2,
    plan: "Registration Form",
    price: "100,000/= TZS",
    features: [
      "Open Date: July 18, 2024",
      "Close Date: August 25, 2024",
      "Access to Events",
      "Eligibility for Awards",
      "Support & Assistance",
    ],
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfXCG4EcGqCmADzNbnV1FvOxEWYbUmJapBaIA-ZTJzSUMvs8A/viewform?embedded=true",
  },
  {
    id: 3,
    plan: "Vote Now",
    price: "Free",
    features: [
      "Open Date:",
      "Close Date",
      "Support Your Favorite Candidate",
      "Real-time Results",
      "Easy and Secure Voting",
    ],
    link: "#",
  },
];

function AppPricing() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleAction = (link, id) => {
    if (id === 2) {
      setModalContent(link);
      setShowModal(true);
    } else {
      window.location.href = link;
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <section id="pricing" className="block pricing-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Forms,Tickets &amp; Vote</h2>
          <div className="subtitle">Secure Your Spot &amp; Join Us</div>
        </div>
        <Row>
          {pricingData.map((pricing) => {
            return (
              <Col sm={4} key={pricing.id}>
                <div className="heading">
                  <h3>{pricing.plan}</h3>
                  <span className="price">{pricing.price}</span>
                </div>
                <div className="content">
                  <ListGroup>
                    {pricing.features.map((feature, index) => {
                      return (
                        <ListGroup.Item key={index}>{feature}</ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
                <div className="btn-holder">
                  <Button
                    variant="primary"
                    onClick={() => handleAction(pricing.link, pricing.id)}
                  >
                    Apply Now
                  </Button>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      {/* Modal for Google Form */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Registration Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={modalContent}
            width="100%"
            height="600"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Google Form"
          >
            Loading…
          </iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default AppPricing;
