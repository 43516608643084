import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";

var testimonialsData = [
  {
    id: 1,
    name: "Amani Njeri",
    description:
      "The East African Youth Panel has been a game-changer for young leaders like me. Their platform has provided invaluable opportunities for networking, learning, and growth. I am truly grateful for the support and recognition from this esteemed organization.",
    designation: "Youth Leader",
  },
  {
    id: 2,
    name: "Moses Okello",
    description:
      "Participating in the EAYP has been an eye-opening experience. The panel’s focus on celebrating and empowering East African youth is remarkable. Their events are well-organized and impactful, offering a significant boost to our projects and initiatives.",
    designation: "Community Organizer",
  },
  {
    id: 3,
    name: "Sofia Zuberi",
    description:
      "EAYP has given a voice to the youth of East Africa, including myself. Their commitment to showcasing our achievements and fostering collaboration is truly inspiring. I’ve gained immense knowledge and confidence through their programs.",
    designation: "Entrepreneur",
  },
];

function AppTestimonials() {
  return (
    <section id="testimonials" className="testimonials-block">
      <Container fluid>
        <div className="title-holder">
          <h2>What Our Participants Say</h2>
          <div className="subtitle">
            Hear from the voices of East African youth
          </div>
        </div>
        <Carousel controls={false}>
          {testimonialsData.map((testimonials) => {
            return (
              <Carousel.Item key={testimonials.id}>
                <blockquote>
                  <p>{testimonials.description}</p>
                  <cite>
                    <span className="name">{testimonials.name}</span>
                    <span className="designation">
                      {testimonials.designation}
                    </span>
                  </cite>
                </blockquote>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </Container>
    </section>
  );
}

export default AppTestimonials;
